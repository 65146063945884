<template>
  <div>
     <Nav ref="navchild"></Nav>
    <div class="banner-width">
      <Banner></Banner>
    </div>
    <div class="mainwidth">
      <Title>
        <template v-slot:one>
          <el-divider content-position="center">新闻中心</el-divider>
        </template>
        <template v-slot:two>
          <span class="sub1">News Center</span>
        </template>
      </Title>
      <div class="content">
        <div class="contenttitle">{{theme}}</div>
        <div style="display: flex;
  justify-content: center;">
          <div class="source">
            来源：{{source }}
            <el-divider direction="vertical"></el-divider>
          </div>

          <div class="date">发布日期：{{uploadTime }}</div>
        </div>
        <div class="center" v-html="article">{{article}}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from "@/views/pc/components/Nav";
import Banner from "@/views/pc/components/Banner";
import Footer from "@/views/pc/components/Footer";
import Title from "@/views/pc/components/Title";
import { newsListDetails } from "@/api/pc.js";
import { appTitle } from '@/utils/get-page-title.js'
export default {
  name: "NewsDetail",
  data() {
    return {
      id: this.$route.params.id,
      theme: "",
      uploadTime: "",
      article: "",
      source: ""
    };
  },
  created() {
    this.getNewsListDetails();
  },
          mounted() {
    var arr = this.$route.path.split("/");
    this.$refs.navchild.setNavIndex("/" + arr[1]);
  },
  methods: {
    getNewsListDetails() {
      let params = {
        id: this.id
      };
      newsListDetails(params).then(res => {
        console.log(params);
        this.theme = res.data.theme;
        document.title = res.data.theme + '-' + appTitle
        this.uploadTime = res.data.uploadTime;
        this.article = res.data.article;
        this.source = res.data.source;
      });
    }
  },
  components: { Nav, Footer, Title, Banner }
};
</script>

<style scoped lang="scss">
.el-divider {
  background: linear-gradient(
    to right,
    #fff,
    #e2e4e9,
    #d8d8d8,
    #d8d8d8,
    #e2e4e9,
    #fff
  );
}
.sub1 {
  background: none;
  color: #999;
}
.el-divider__text {
  background: #fff;
}
.banner-width {
  background: url("~@/assets/pcimg/bg1.jpg") center no-repeat;
}
.mainwidth {
  width: 1200px;
  margin: 0 auto;

  .content {
    min-height: calc(100vh - 412px);
    margin-top: 40px;
    .contenttitle {
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }
    .date {
      font-size: 18px;
      color: #999;
      padding-top: 10px;
      text-align: center;
    }

    .source {
      font-size: 18px;
      color: #999;
      padding-top: 10px;
      text-align: center;
      padding-right: 8px;
    }
    .center {
      line-height: 30px;
      margin-top: 20px;
      img {
        margin-top: 20px;
      }
    }
  }
  .list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>